<template>
  <div class="admin-dashboard">
    <nav class="breadcrumb mt-5" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/admin">Admin Settings</router-link></li>
      </ul>
    </nav>
    <section class="is-primary mt-5">
      <div>
        <div class="container">
          <h1 class="title">Admin Cockpit</h1>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns is-multiline is-centered">
          <div class="column is-one-quarter" v-for="(option, index) in settingsOptions" :key="index">
            <router-link :to="option.route">
              <div class="card option-card">
                <div class="card-content has-text-centered">
                  <p class="title is-4">{{ option.name }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AdminDashboard',
  data() {
    return {
      settingsOptions: [
        { name: 'User Management', route: '/admin/user-management' },
        { name: 'Entity Management', route: '/admin/entity-management' },
        { name: 'API Key Management', route: '/admin/api-key-management' },
        { name: 'Enrichment Management', route: '/admin/enrichment-management' },
        { name: 'Group & Permission Management', route: '/admin/permission-management' }, // New Menu Item
      ],
    };
  },
};
</script>

<style scoped>
.admin-dashboard {
  text-align: center;
}

.card {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-10px);
}

.option-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; /* Set a fixed height for uniform size */
  width: 100%; /* Ensure the card takes full width of the column */
}

.option-card .card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
