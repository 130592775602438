<template>
    <div v-if="isVisible" class="modal is-active">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Save as Template</p>
          <button class="delete" @click="close" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">Template Name</label>
            <div class="control">
              <input class="input" type="text" v-model="templateName" placeholder="Enter template name">
            </div>
          </div>
          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <input class="input" type="text" v-model="templateDescription" placeholder="Enter Description">
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
            <div class="buttons">
                <button class="button is-success" @click="saveTemplate">Save</button>
                <button class="button" @click="close">Cancel</button>
            </div>
        </footer>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        templateName: '',
        templateDescription: ''
      };
    },
    methods: {
      close() {
        this.$emit('close');
      },
      saveTemplate() {
        this.$emit('save-template', this.templateName, this.templateDescription);
      }
    }
  };
  </script>
  
  <style>
  .modal-card {
    max-width: 400px;
  }
  </style>
  