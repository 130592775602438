<template>
  <div class="card">
    <div class="card-content">
      <div class="pie-chart-container">
        <Pie :data="chartData" :options="chartOptions"></Pie>
      </div>
    </div>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

export default {
  name: 'PieChartComponent',
  components: {
    Pie,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.data.map(item => item.buyer),
        datasets: [
          {
            label: 'Top 5 Customers (YTD)',
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
            data: this.data.map(item => item.count),
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true, // Enable the title
            text: 'Top 5 Customers (YTD)', // Set the title text
            font: {
              size: 12, // Adjust the font size
            },
            padding: {
              top: 10,
              bottom: 10,
            },
            align: 'center', // Align the title (default is center)
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.pie-chart-container {
  width: 100%;
  height: 200px; /* Ensure the chart height matches the line chart height */
}
</style>
