<template>
  <div class="card upload-csv">
    <h2 class="title is-size-6" style="display: flex; justify-content: center">Upload CSV Invoices</h2>
    <form @submit.prevent="uploadCSV">
      <div class="file has-name is-boxed" style="display: flex; justify-content: center;">
        <label class="file-label">
          <input class="file-input" type="file" @change="handleFileUpload" ref="file">
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">Choose a file…</span>
          </span>
          <span class="file-name">{{ fileName }}</span>
        </label>
      </div>
      <div style="display: flex; justify-content: center;">
        <button class="button is-primary is-fullwidth" type="submit" :disabled="!file">Upload</button>
      </div>
    </form>
    <div v-if="responseMessage">
      <h2 class="subtitle is-size-7">Response</h2>
      <pre class="is-size-7">{{ responseMessage }}</pre>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UploadCSV',
  data() {
    return {
      file: null,
      fileName: '',
      responseMessage: null
    }
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
    },
    async uploadCSV() {
      if (!this.file) return;

      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('created_by', localStorage.getItem('userid')); // Add the created_by field

      try {
        const response = await axios.post('/api/einvoicing_json/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-csrftoken': 'LMfRCqZF851EkqvB91mJvbDNV1nWHKJ0',
            'authorization': process.env.VUE_APP_API_KEY,
            'approval': 'true'
          }
        });
        const dataString = JSON.stringify(response.data).replace(/[{}]/g, '');
        this.responseMessage = dataString;
      } catch (error) {
        console.error('Error uploading file:', error);
        this.responseMessage = error.response ? JSON.stringify(error.response.data).replace(/[{}]/g, '') : 'An error occurred';
      }
    }
  }

}
</script>

<style scoped>
.upload-csv {
  padding: 20px;
  margin-bottom: 20px;
}

.file-label {
  margin-bottom: 10px;
}
</style>
