<template>
  <div class="page-my-account mt-5">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li class="is-active"><router-link to="/dashboard/my-account" aria-current="true">My account</router-link></li>
      </ul>
    </nav>

    <h1 class="title">My account</h1>

    <div>
      <img :src="profileImageUrl" alt="User Image" class="user-image" />
    </div>

    <strong>Username: </strong>{{ userProfile.username }}<br>
    <strong>Name: </strong>{{ userProfile.first_name }} {{ userProfile.last_name }}<br>
    <strong>Access Type: </strong>{{ userProfile.access_type }}<br>
    <strong>Entities:</strong>
    <ul>
      <li v-for="entity in userProfile.sender_entities" :key="entity.entity_code">{{ entity.name }}</li>
    </ul>
    <strong>Roles:</strong>
    <ul>
      <li v-for="group in userProfile.groups" :key="group.id">{{ group.name }}</li>
    </ul>

    <hr>

    <div class="buttons">
      <button @click="logout" class="button is-danger">Log out</button>
      <button @click="openResetPasswordModal" class="button is-primary">Reset Password</button>
    </div>

    <!-- Password Reset Modal -->
    <div v-if="showPasswordModal" class="modal is-active">
      <div class="modal-background" @click="closePasswordModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Reset Password</p>
          <button class="delete" aria-label="close" @click="closePasswordModal"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">New Password</label>
            <div class="control">
              <input type="password" v-model="newPassword" class="input" placeholder="Enter new password" />
            </div>
          </div>

          <div class="field">
            <label class="label">Confirm Password</label>
            <div class="control">
              <input type="password" v-model="confirmPassword" class="input" placeholder="Confirm new password" />
            </div>
          </div>

          <p v-if="passwordError" class="has-text-danger">{{ passwordError }}</p>
        </section>
        <footer class="modal-card-foot buttons">
          <button class="button is-success" @click="confirmResetPassword">Submit</button>
          <button class="button" @click="closePasswordModal">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';

export default {
  name: 'MyAccount',
  data() {
    return {
      userProfile: {
        username: '',
        access_type: '',
        first_name: '',
        last_name: '',
        sender_entities: [],
        groups: []
      },
      profileImageUrl: '',
      showPasswordModal: false,
      newPassword: '',
      confirmPassword: '',
      passwordError: ''
    };
  },
  async mounted() {
    await this.getUserProfile();
  },
  methods: {
    async getUserProfile() {
      try {
        const response = await axios.get('/api/v1/user-profile/');
        this.userProfile = response.data;
        this.profileImageUrl = response.data.image || 'https://via.placeholder.com/150';
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    },
    openResetPasswordModal() {
      this.showPasswordModal = true;
      this.newPassword = '';
      this.confirmPassword = '';
      this.passwordError = '';
    },
    closePasswordModal() {
      this.showPasswordModal = false;
    },
    async confirmResetPassword() {
      if (!this.newPassword || !this.confirmPassword) {
        this.passwordError = 'Both fields are required.';
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.passwordError = 'Passwords do not match.';
        return;
      }

      // Show confirmation dialog
      if (!confirm('Are you sure you want to change your password?')) {
        return;
      }

      try {
        await axios.post(
          '/api/v1/user/reset-password-vv/', 
          { new_password: this.newPassword },
          { headers: { Authorization: `Token ${this.$store.state.token}` } }
        );

        toast({
          message: 'Password successfully updated.',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: 'top-right'
        });

        this.closePasswordModal();
      } catch (error) {
        console.error('Error resetting password:', error);
        toast({
          message: 'Error resetting password. Please try again.',
          type: 'is-danger',
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: 'top-right'
        });
      }
    },
    async logout() {
      try {
        await axios.post('/api/v1/token/logout/');

        // Clear local storage
        localStorage.removeItem('username');
        localStorage.removeItem('userid');
        localStorage.removeItem('token');

        // Clear axios authorization header
        axios.defaults.headers.common['Authorization'] = '';

        // Commit Vuex mutation to remove token
        this.$store.commit('removeToken');

        // Redirect to home page
        this.$router.push('/');
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style>
.user-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}
.modal-card {
  max-width: 400px;
  width: 100%;
}
</style>
