<template>
  <div class="columns is-gapless is-multiline item-form">
    <div class="column is-3">
      <div class="field">
        <label class="label is-size-7">Description</label>
        <div class="control">
          <multiselect
            v-model="selectedItem"
            :options="items"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="item_description"
            track-by="item_description"
            placeholder="Select a description"
            @select="updateSelectedItem"
            class="is-small multiselect-custom"
          ></multiselect>
        </div>
      </div>
    </div>

    <div class="column is-1">
      <div class="field">
        <label class="label is-size-7">Class #</label>
        <div class="control">
          <input type="text" class="input is-small" v-model="item.classification" disabled>
        </div>
      </div>
    </div>

    <div class="column is-1">
      <div class="field">
        <label class="label is-size-7">Unit Price</label>
        <div class="control">
          <input type="number" step="0.01" class="input is-small" v-model.number="item.unit_price" @input="updateItem" required>
        </div>
      </div>
    </div>

    <div class="column is-1">
      <div class="field">
        <label class="label is-size-7">Qty</label>
        <div class="control">
          <input type="number" class="input is-small" v-model.number="item.quantity" @input="updateItem" required>
        </div>
      </div>
    </div>

    <div class="column is-1">
      <div class="field">
        <label class="label is-size-7">Tax Type</label>
        <div class="control">
          <input type="text" class="input is-small" v-model="item.tax_type" disabled>
        </div>
      </div>
    </div>

    <div class="column is-1">
      <div class="field">
        <label class="label is-size-7">Tax rate</label>
        <div class="control">
          <input type="text" class="input is-small" v-model="item.tax_rate" disabled>
        </div>
      </div>
    </div>

    <div class="column is-1">
      <div class="field">
        <label class="label is-size-7">Disc %</label>
        <div class="control">
          <input type="number" step="1" class="input is-small" v-model.number="item.discount_rate" @input="updateItem" required>
        </div>
      </div>
    </div>

    <div class="column is-1">
      <div class="field">
        <label class="label is-size-7">Disc Amt</label>
        <div class="control">
          <input type="number" step="1" class="input is-small" v-model.number="item.discount_amount" disabled>
        </div>
      </div>
    </div>

    <div class="column is-2">
      <div class="field">
        <label class="label is-size-7">Gross amount</label>
        <div class="control">
          <input type="text" class="input is-small" :value="gross_amount" disabled>
        </div>
      </div>
    </div>
    <div class="column is-1 is-vcentered mt-5 ml-3">
      <button @click="$emit('delete-item', item.id)" class="delete is-small">
        <span class="icon">
          <i class="fas fa-trash-alt"></i>
        </span>
      </button>
    </div>
  </div>
  <div v-if="item.tax_rate == 0" class="columns">
    <div class="column is-3">
      <div class="field">
        <label class="label is-size-7">Tax Exemption Reason</label>
        <div class="control">
          <input type="text" class="input is-small" v-model="item.tax_exemption_reason">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  name: 'ItemForm',
  components: {
    Multiselect
  },
  props: {
    initialItem: Object
  },
  data() {
    return {
      item: {
        ...this.initialItem,
        discount_rate: this.initialItem.discount_rate || 0  // Set default value to 0
      },
      items: [],
      selectedItem: null
    };
  },
  computed: {
    gross_amount() {
      if (this.item.unit_price && this.item.quantity && this.item.tax_rate != null) {
        const total_excluding_tax = parseFloat(this.item.unit_price) * this.item.quantity;
        const disc_amount = total_excluding_tax * (this.item.discount_rate / 100);
        const gross_amount = (total_excluding_tax - disc_amount) + ((total_excluding_tax - disc_amount) * (this.item.tax_rate / 100));
        return gross_amount.toFixed(2);  // Ensure two decimal points
      }
      return '0.00';
    },
  },
  methods: {
    updateItem() {
      if (this.item.unit_price && this.item.quantity && this.item.tax_rate != null) {
        const total_excluding_tax = parseFloat(this.item.unit_price) * this.item.quantity;
        const disc_amount = total_excluding_tax * (this.item.discount_rate / 100);
        this.item.total_excluding_tax = (total_excluding_tax - disc_amount).toFixed(2);  // Ensure two decimal points
        this.item.subtotal = total_excluding_tax.toFixed(2);
        this.item.tax_amount = ((total_excluding_tax - disc_amount) * (this.item.tax_rate / 100)).toFixed(2);
        this.item.discount_amount = disc_amount.toFixed(2);
        if (this.item.tax_rate == 0) {
          this.item.tax_exemption_reason = "NOT APPLICABLE";  // Ensure tax_exemption_reason is set to "NOT APPLICABLE"
        } else {
          delete this.item.tax_exemption_reason;  // Remove tax_exemption_reason if tax_rate is not 0
        }
        this.$emit('updateItem', this.item);
      }
    },
    updateSelectedItem(selectedItem) {
      if (selectedItem) {
        this.item.classification = selectedItem.classification;
        this.item.item_description = selectedItem.item_description;
        this.item.tax_type = selectedItem.tax_type;
        this.item.tax_rate = selectedItem.tax_rate;
        this.updateItem();
      }
    },
    fetchItems() {
      axios.get('/api/v1/portal-items')
        .then(response => {
          this.items = response.data;
          // Set selectedItem based on initialItem
          this.selectedItem = this.items.find(i => i.item_description === this.item.item_description);
          // Set tax_exemption_reason if tax_rate is 0
          if (this.item.tax_rate == 0) {
            this.item.tax_exemption_reason = "NOT APPLICABLE";
          }
        })
        .catch(error => {
          console.error('Error fetching items:', error);
        });
    }
  },
  created() {
    this.fetchItems();
  }
};
</script>

<style scoped>
.field {
  column-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
}

.field .label {
  flex: 1;
  height: 2.5em; /* Adjust as needed */
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 1.2rem;
}

.field .control .input,
.field .control .select select {
  line-height: 2.7em; /* Adjust as needed */
  font-size: 0.8em; /* Smaller font size */
}

.multiselect-custom .multiselect__single, 
.multiselect-custom .multiselect__placeholder,
.multiselect__input {
  line-height: 2.0em;
  font-size: 2.0em; /* Smaller font size */
}

.multiselect-custom .multiselect__select,
.multiselect-custom .multiselect__caret {
  font-size: 3.0em; /* Smaller icon size */
}

/* Hide stepper controls for number inputs */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
