<template>
  <div class="page-customers">
    <nav class="breadcrumb mt-5" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li class="is-active"><router-link to="/dashboard/customers" aria-current="true">Customers</router-link></li>
      </ul>
    </nav>

    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Customers</h1>
        <button class="button is-primary" @click="openNewCustomerQuickview">New Customer</button>
      </div>

      <div class="column is-12 tabs is-toggle is-small mt-4">
        <ul>
          <li :class="{ 'is-active': selectedTab === 'All' }" @click="selectedTab = 'All'">
            <a>All</a>
          </li>
          <li :class="{ 'is-active': selectedTab === 'BRN' }" @click="selectedTab = 'BRN'">
            <a>BRN</a>
          </li>
          <li :class="{ 'is-active': selectedTab === 'NRIC' }" @click="selectedTab = 'NRIC'">
            <a>NRIC</a>
          </li>
          <li :class="{ 'is-active': selectedTab === 'NA' }" @click="selectedTab = 'NA'">
            <a>Consolidated</a>
          </li>
        </ul>
      </div>

      <div class="columns" style="gap: 15px; margin-top: 10px; padding-left: 20px">
        <input v-model="filters.buyerName" type="text" class="input is-rounded is-small" placeholder="Buyer Name">
        <button class="button is-info is-rounded is-small" @click="downloadCSV">CSV Report</button>
        <div class="select is-link is-small">
          <select v-model="itemsPerPage">
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      <div class="column is-12">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th @click="sortBy('name')" class="is-size-7">Customer Name <button :class="getSortClass('name')"></button></th>
              <th @click="sortBy('tin_no')" class="is-size-7">TIN No <button :class="getSortClass('tin_no')"></button></th>
              <th @click="sortBy('identity_number')" class="is-size-7">Identity No <button :class="getSortClass('identity_number')"></button></th>
              <th @click="sortBy('identity_type')" class="is-size-7">Identity Type <button :class="getSortClass('identity_type')"></button></th>
              <th @click="sortBy('email')" class="is-size-7">Email <button :class="getSortClass('email')"></button></th>
              <th @click="sortBy('city')" class="is-size-7">City <button :class="getSortClass('city')"></button></th>
              <th @click="sortBy('contact_number')" class="is-size-7">Contact Number <button :class="getSortClass('contact_number')"></button></th>
              <th class="is-size-7">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in filteredCustomers" :key="customer.id" class="customer-row">
              <td style="text-align: left" class="is-size-7">{{ customer.name }}</td>
              <td class="is-size-7">{{ customer.tin_no }}</td>
              <td class="is-size-7">{{ customer.identity_number }}</td>
              <td style="text-align: center" class="is-size-7">{{ customer.identity_type }}</td>
              <td class="is-size-7">{{ customer.email }}</td>
              <td class="is-size-7">{{ customer.city }}</td>
              <td style="text-align: left" class="is-size-7">{{ customer.contact_number }}</td>
              <td>
                <button class="button is-small is-info" @click="editCustomer(customer)" v-if="customer.source_ui">Edit</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1" class="button is-ghost is-small">Previous</button>
          <span class="strong is-size-7">Page {{ currentPage }} of {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages" class="button is-ghost is-small">Next</button>
        </div>
      </div>
    </div>

    <AddCustomer 
      v-if="showQuickview" 
      :customer="newCustomer" 
      @close="closeQuickview" 
      @customerSaved="getCustomers"
      ref="AddCustomer" 
    />
  </div>
</template>

<script>
import axios from 'axios';
import AddCustomer from '@/components/AddCustomer.vue';

export default {
  name: 'ViewCustomers',
  components: {
    AddCustomer,
  },
  data() {
    return {
      customers: [],
      newCustomer: {
        name: '',
        entity_code: '',
        tin_no: '',
        identity_type: '',
        identity_number: '',
        email: '',
        city: '',
        contact_number: '',
        source_ui: true,
      },
      showQuickview: false,
      filters: {
        buyerName: '',
        identityType: '',
      },
      sortKey: '',
      sortOrders: {
        name: 1,
        tin_no: 1,
        identity_number: 1,
        identity_type: 1,
        email: 1,
        city: 1,
        contact_number: 1,
      },
      currentPage: 1,
      itemsPerPage: 10,
      selectedTab: 'All',
    };
  },
  mounted() {
    this.getCustomers();
  },
  computed: {
    sortedCustomers() {
      return this.customers.slice().sort((a, b) => {
        let keyA = this.getKey(a, this.sortKey);
        let keyB = this.getKey(b, this.sortKey);
        if (keyA < keyB) return -1 * this.sortOrders[this.sortKey];
        if (keyA > keyB) return 1 * this.sortOrders[this.sortKey];
        return 0;
      });
    },
    filteredCustomers() {
      let customers = this.sortedCustomers;
      if (this.selectedTab !== 'All') {
        customers = customers.filter(customer => customer.identity_type === this.selectedTab);
      }
      if (this.filters.buyerName) {
        customers = customers.filter(customer => customer.name.toLowerCase().includes(this.filters.buyerName.toLowerCase()));
      }
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return customers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.sortedCustomers.length / this.itemsPerPage);
    }
  },
  methods: {
    getKey(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrders[key] = this.sortOrders[key] * -1;
      } else {
        this.sortKey = key;
        this.sortOrders[key] = 1;
      }
    },
    getSortClass(key) {
      if (this.sortKey !== key) {
        return '';
      }
      return this.sortOrders[key] === 1 ? 'sort-asc' : 'sort-desc';
    },
    async getCustomers() {
      try {
        const response = await axios.get('/api/v1/customers/');
        this.customers = response.data;
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    },
    downloadCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Name,Entity Code,TIN No,Identity Type,Identity Number,Email,City,Contact Number\n"; // CSV Header
      this.customers.forEach(customer => {
        let row = `${customer.name},${customer.entity_code},${customer.tin_no},${customer.identity_type},${customer.identity_number},${customer.email},${customer.city},${customer.contact_number}`;
        csvContent += row + "\n";
      });

      const date = new Date();
      const dateString = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `customers_${dateString}.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
    },
    openNewCustomerQuickview() {
      this.newCustomer = {
        name: '',
        entity_code: '',
        tin_no: '',
        identity_type: '',
        identity_number: '',
        email: '',
        city: '',
        contact_number: '',
        source_ui: true,
      };
      this.showQuickview = true;
    },
    editCustomer(customer) {
      this.newCustomer = { ...customer };
      this.showQuickview = true;
    },
    closeQuickview() {
      this.showQuickview = false;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style scoped>
.customer-row {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.pagination button {
  margin: 0 0.5rem;
}
.sort-asc::after {
  content: ' ▲';
}
.sort-desc::after {
  content: ' ▼';
}
</style>
