<template>
  <div class="section">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/admin">Admin Settings</router-link></li>
        <li class="is-active"><router-link to="/dashboard/api-key-management" aria-current="true">api-key-management</router-link></li>
      </ul>
    </nav>
    <div class="container">
      <h1 class="title">API Key Management</h1>
      <div class="columns is-12 m-5">
        <button class="button is-primary" @click="openCreateModal">Create API Key</button>
      </div>
        <div v-if="showBanner" class="notification is-warning m-5">
        <button class="delete" @click="closeBanner"></button>
        <strong>Copy your new API key:</strong>
        <p>{{ newApiKey }}</p>
        <p>This key cannot be viewed again.</p>
      </div>
      <div class="column is-12">
      <table class="table is-small">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created</th>
            <th>Has Expired</th>
            <th>Revoked</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="apiKey in apiKeys" :key="apiKey.id">
            <td class="is-size-7">{{ apiKey.name }}</td>
            <td class="is-size-7">{{ formatDate(apiKey.created) }}</td>
            <td class="is-size-7">{{ apiKey.has_expired ? 'Yes' : 'No' }}</td>
            <td class="is-size-7">{{ apiKey.revoked ? 'Yes' : 'No' }}</td>
            <td class="is-size-7">
              <button class="button is-info is-small ml-2" @click="openEditModal(apiKey)">Edit</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <create-edit-api-key-modal
      v-if="isModalOpen"
      :is-edit="isEdit"
      :api-key="selectedApiKey"
      @close="closeModal"
      @submit="handleModalSubmit"
      @delete="deleteApiKey"
    ></create-edit-api-key-modal>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import CreateEditApiKeyModal from './CreateEditApiKeyModal.vue'; // Assuming you create a separate component for the modal
import { toast } from 'bulma-toast';

export default {
  components: {
    CreateEditApiKeyModal,
  },
  data() {
    return {
      apiKeys: [],
      isModalOpen: false,
      isEdit: false,
      selectedApiKey: null,
      showBanner: false,
      newApiKey: '',
    };
  },
  methods: {
    openCreateModal() {
      this.isEdit = false;
      this.selectedApiKey = null;
      this.isModalOpen = true;
    },
    openEditModal(apiKey) {
      this.isEdit = true;
      this.selectedApiKey = { ...apiKey };
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleModalSubmit(apiKey) {
      if (this.isEdit) {
        this.updateApiKey(apiKey);
      } else {
        this.createApiKey(apiKey);
      }
    },
    createApiKey(apiKey) {
      axios.post('/api/v1/authentication/api-keys/', apiKey, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
      .then(response => {
        this.apiKeys.push(response.data);
        this.newApiKey = response.data.key;
        this.showBanner = true;
        toast({ message: 'API Key created successfully', type: 'is-success' });
        this.closeModal();
      });
    },
    updateApiKey(apiKey) {
      axios.put(`/api/v1/authentication/api-keys/${apiKey.id}/`, apiKey, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
      .then(response => {
        const index = this.apiKeys.findIndex(key => key.id === apiKey.id);
        if (index !== -1) {
          this.$set(this.apiKeys, index, response.data);
        }
        toast({ message: 'API Key updated successfully', type: 'is-success' });
        this.closeModal();
      });
    },
    deleteApiKey(apiKeyId) {
      if (confirm('Are you sure you want to delete this API Key?')) {
        axios.delete(`/api/v1/authentication/api-keys/${apiKeyId}/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          }
        }).then(() => {
          this.apiKeys = this.apiKeys.filter(apiKey => apiKey.id !== apiKeyId);
          toast({ message: 'API Key deleted successfully', type: 'is-success' });
          this.closeModal();
        });
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    closeBanner() {
      this.showBanner = false;
    },
  },
  created() {
    axios.get('/api/v1/authentication/api-keys/', {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
      }
    })
    .then(response => {
      this.apiKeys = response.data.results;
    });
  },
};
</script>

<style scoped>
.ml-2 {
  margin-left: 0.5rem;
}
</style>
