import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'bulma/css/bulma.css';
import 'bulma-quickview/dist/css/bulma-quickview.min.css';
import bulmaQuickview from 'bulma-quickview/src/js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from './fontawesome';
import { faEye, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
// import './assets/main.scss'

library.add(faEye, faCheck, faTimes);

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`; // or however your token is stored

const app = createApp(App);

app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router, axios);

// Initialize Bulma Quickview
document.addEventListener('DOMContentLoaded', () => {
  bulmaQuickview.attach();
});

app.mount('#app');
