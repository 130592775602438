import { createRouter, createWebHistory } from 'vue-router'
import UserDashboard from '../views/dashboard/UserDashboard.vue'
import MyAccount from '../views/dashboard/MyAccount.vue'
import LogIn from '../views/LogIn.vue'
import ViewInvoices from '../views/dashboard/ViewInvoices.vue'
import UploadInvoice from '../views/dashboard/UploadInvoice.vue'
import CreateInvoice from '../views/dashboard/CreateInvoice.vue'
import InvoiceApproval from '../views/dashboard/InvoiceApproval.vue'
import EditInvoice from "../views/dashboard/EditInvoice"
import NewInvoicePage from "../views/dashboard/NewInvoicePage"
import Customers from "../views/dashboard/CustomerTable.vue"
import ItemsTable from "../views/dashboard/ItemsTable.vue"
import ReportForm from "../views/dashboard/ReportForm.vue"
// import TemplateForm from "../views/dashboard/TemplateForm"

import AdminDashboard from '../views/admin/AdminSettings.vue'
import UserManagement from '../views/admin/UserManagement.vue'
import EntityManagement from '../views/admin/EntityManagement.vue'
import ApiKeyManagement from '../views/admin/ApiKeyManagement.vue'
import EnrichmentManagement from '../views/admin/EnrichmentManagement.vue'

import store from '../store'
import GroupPermissionManagement from '@/views/admin/GroupPermissionManagement.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LogIn
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/dashboard',
    name: 'UserDashboard',
    component: UserDashboard,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/invoices',
    name: 'Invoices',
    component: ViewInvoices,
    props: route => ({ selectedTab: route.query.tab || 'All' }),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/invoices/upload',
    name: 'UploadInvoice',
    component: UploadInvoice,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/invoices/create',
    name: 'AddInvoice',
    component: CreateInvoice,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/invoices/edit/:uuid',
    name: 'EditInvoice',
    component: EditInvoice,
    meta: {
      requireLogin: true
    },
    props: true
  },
  {
    path: '/dashboard/invoices/approval',
    name: 'InvoiceApproval',
    component: InvoiceApproval,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/create-document',
    name: 'NewInvoicePage',
    component: NewInvoicePage,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/customers',
    name: 'Customers',
    component: Customers,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/itemstable',
    name: 'ItemsTable',
    component: ItemsTable,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard/report',
    name: 'ReportForm',
    component: ReportForm,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: {
      requireLogin: true,
      requireAdmin: true
    }
  },
  {
    path: '/admin/user-management',
    name: 'UserManagement',
    component: UserManagement,
    meta: {
      requireLogin: true,
      requireAdmin: true
    }
  },
  {
    path: '/admin/entity-management',
    name: 'EntityManagement',
    component: EntityManagement,
    meta: {
      requireLogin: true,
      requireAdmin: true
    }
  },
  {
    path: '/admin/api-key-management',
    name: 'ApiKeyManagement',
    component: ApiKeyManagement,
    meta: {
      requireLogin: true,
      requireAdmin: true
    }
  },
  {
    path: '/admin/enrichment-management',
    name: 'EnrichmentManagement',
    component: EnrichmentManagement,
    meta: {
      requireLogin: true,
      requireAdmin: true
    }
  },
  {
    path: '/admin/permission-management',
    name: 'PermissionManagement',
    component: GroupPermissionManagement,
    meta: {
      requireLogin: true,
      requireAdmin: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next('/log-in')
  } else if (to.matched.some(record => record.meta.requireAdmin) && !store.getters.isSuperAdmin) {
    next('/dashboard')
  } else {
    next()
  }
})

export default router
