<template>
  <div class="card invoice-table">
    <div class="columns">
      <h2 class="column is-half title is-size-6">Previous Documents</h2>
    </div>
    <table class="table is-fullwidth is-striped">
      <thead>
        <tr class="is-size-7">
          <th>Invoice Number</th>
          <th>Type</th>
          <th>Created At</th>
          <th>Status</th>
          <th>Reviewer</th>
          <th>Last Update</th>
          <th>Action</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="invoice in invoices" :key="invoice.invoice_number" class="is-size-7">
          <td>{{ invoice.invoice_number }}</td>
          <td>{{ invoice.document_type }}</td>
          <td>{{ formatDate(invoice.created_at) }}</td>
          <td>{{ invoice.status }}</td>
          <td>{{ invoice.approved_by ? invoice.approved_by.email : '' }}</td>
          <td>{{ formatDate(invoice.modified_at) }}</td>
          <td>
            <span class="icon-container" v-if="invoice.status === 'Approval Rejected'" @click="editInvoice(invoice.uuid)" title="Edit Invoice">
              <font-awesome-icon icon="edit" class="has-text-primary"/>
            </span>
            <span class="icon-container" v-if="invoice.status === 'Pending Approval'" @click="confirmCancelInvoice(invoice.uuid)" title="Cancel Invoice">
              <font-awesome-icon icon="times" class="has-text-danger"/>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'InvoiceTable',
  data() {
    return {
      invoices: [],
    }
  },
  methods: {
    fetchInvoices() {
      axios.get('/api/v1/summary-invoices/ui_invoices/')
        .then(response => {
          console.log('Response data:', response.data); // Debug log
          this.invoices = Array.isArray(response.data.results) ? response.data.results : [];
        })
        .catch(error => {
          console.error('Error fetching invoices:', error);
        });
    },
    confirmCancelInvoice(uuid) {
      if (window.confirm('This action is not reversible. Do you want to proceed?')) {
        this.cancelInvoice(uuid);
      }
    },
    editInvoice(uuid) {
      this.$router.push({ name: 'EditInvoice', params: { uuid } });
    },
    cancelInvoice(uuid) {
      axios.post(`/api/approve-invoice/${uuid}/`, { status: 'User Cancelled' }, {
          withCredentials: true
        })
        .then(() => {
          this.fetchInvoices();
        })
        .catch(error => {
          console.error('Error cancelling invoice:', error);
        });
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }
  },
  created() {
    this.fetchInvoices();
  },
}
</script>

<style scoped>
.invoice-table {
  padding: 20px;
  margin-top: 20px;
}
.table {
  padding-right: 20px;
}
.icon-container {
  cursor: pointer;
  margin-left: 5px;
  position: relative;
}
.icon-container:hover::after {
  content: attr(title);
  position: absolute;
  top: -1.5em;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.75em;
  white-space: nowrap;
}
</style>
