<template>
  <div class="page-login">
    <div class="columns">
      <div class="column is-12">
        <h1 class="title">Log in</h1>

        <form @submit.prevent="submitForm">
          <div class="field">
            <label>Username</label>
            <div class="control">
              <input type="email" name="username" class="input" v-model="username">
            </div>
          </div>

          <div class="field">
            <label>Password</label>
            <div class="control">
              <input type="password" name="password" class="input" v-model="password">
            </div>
          </div>

          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" :key="error">{{ error }}</p>
          </div>

          <div class="field">
            <div class="control">
              <button class="button is-success">Log in</button>
            </div>
          </div>
        </form>

        <hr>

        <p><strong>Email Finance or IT Support for access or to reset your login credentials.</strong></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LogIn',
  data() {
    return {
      username: '',
      password: '',
      errors: []
    }
  },
  async created() {
    // Check if token exists in local storage
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      try {
        // Validate the token by making an API request
        const userResponse = await axios.get("/api/v1/user-profile");
        this.$store.commit('setUser', {
          username: userResponse.data.username,
          id: userResponse.data.id,
          email: userResponse.data.email,
          first_name: userResponse.data.first_name,
          last_name: userResponse.data.last_name,
          access_type: userResponse.data.access_type,
          image: userResponse.data.image,
          sender_entities: userResponse.data.sender_entities,
          store_code: userResponse.data.store_code
        });

        localStorage.setItem('username', userResponse.data.username);
        localStorage.setItem('userid', userResponse.data.id);
        localStorage.setItem('email', userResponse.data.email);
        localStorage.setItem('first_name', userResponse.data.first_name);
        localStorage.setItem('last_name', userResponse.data.last_name);
        localStorage.setItem('access_type', userResponse.data.access_type);
        localStorage.setItem('image', userResponse.data.image);
        localStorage.setItem('sender_entities', JSON.stringify(userResponse.data.sender_entities));
        localStorage.setItem('store_code', JSON.stringify(userResponse.data.store_code));

        // Redirect to the dashboard if the token is valid
        this.$router.push('/dashboard');
      } catch (error) {
        // If the token is not valid, clear the local storage and authorization header
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        localStorage.removeItem("userid");
        localStorage.removeItem("email");
        localStorage.removeItem("first_name");
        localStorage.removeItem("last_name");
        localStorage.removeItem("access_type");
        localStorage.removeItem("image");
        localStorage.removeItem("sender_entities");
        localStorage.removeItem("store_code");
        axios.defaults.headers.common["Authorization"] = "";
        console.error('Invalid token, cleared from local storage.');
      }
    }
  },
  methods: {
    async submitForm() {
      this.errors = [];
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");

      const formData = {
        username: this.username,
        password: this.password
      };

      try {
        const response = await axios.post("/api/v1/token/login/", formData);
        const token = response.data.auth_token;

        this.$store.commit('setToken', token);
        axios.defaults.headers.common["Authorization"] = "Token " + token;
        localStorage.setItem("token", token);

        const userResponse = await axios.get("/api/v1/user-profile");
        this.$store.commit('setUser', {
          username: userResponse.data.username,
          id: userResponse.data.id,
          email: userResponse.data.email,
          first_name: userResponse.data.first_name,
          last_name: userResponse.data.last_name,
          access_type: userResponse.data.access_type,
          image: userResponse.data.image,
          sender_entities: userResponse.data.sender_entities,
          store_code: userResponse.data.store_code
        });

        localStorage.setItem('username', userResponse.data.username);
        localStorage.setItem('userid', userResponse.data.id);
        localStorage.setItem('email', userResponse.data.first_name);
        localStorage.setItem('first_name', userResponse.data.email);
        localStorage.setItem('last_name', userResponse.data.last_name);
        localStorage.setItem('access_type', userResponse.data.access_type);
        localStorage.setItem('image', userResponse.data.image);
        localStorage.setItem('sender_entities', JSON.stringify(userResponse.data.sender_entities));
        localStorage.setItem('store_code', JSON.stringify(userResponse.data.store_code));

        // Fetch and store user permissions
        await this.$store.dispatch('fetchPermissions');

        this.$router.push('/dashboard');
      } catch (error) {
        if (error.response) {
          for (const property in error.response.data) {
            this.errors.push(`${property}: ${error.response.data[property]}`);
          }
        } else if (error.message) {
          this.errors.push(error.message);
        } else {
          this.errors.push('An unknown error occurred.');
        }
        console.error(error);
      }
    }
  }
}
</script>

<style scoped>
.page-login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
</style>
