<template>
  <div class="card">
    <div class="card-content">
      <div class="line-chart-container">
        <Line :data="chartData" :options="chartOptions"></Line>
      </div>
    </div>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

export default {
  name: 'LineChartComponent',
  components: {
    Line,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.data.map(item => item.month),
        datasets: [
          {
            label: 'Invoices Processed (YTD)',
            backgroundColor: '#f87979',
            borderColor: '#f87979',
            data: this.data.map(item => item.count),
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
};
</script>

<style scoped>
.line-chart-container {
  width: 100%;
  height: 200px; /* Ensure the chart height matches the pie chart height */
}
</style>
