<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ item ? 'Edit Item' : 'Add New Item' }}</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="saveItem">
          <div class="field">
            <label class="label">Classification *</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="formItem.classification" required>
                  <option v-for="code in classificationCodes" :key="code.Code" :value="code.Code">
                    {{ code.Description }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Description *</label>
            <div class="control">
              <input v-model="formItem.item_description" class="input" type="text" placeholder="Description" required>
            </div>
          </div>

          <div class="field">
            <label class="label">Tax Type *</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="formItem.tax_type" required>
                  <option v-for="tax in taxTypes" :key="tax.Code" :value="tax.Code">
                    {{ tax.Description }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Tax Rate *</label>
            <div class="control">
              <input v-model="formItem.tax_rate" class="input" type="number" placeholder="Tax Rate">
            </div>
          </div>
          
          <button class="button is-primary" type="submit">Save</button>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="closeModal">Cancel</button>
      </footer>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'AddItemModal',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formItem: {
        classification: '',
        item_description: '',
        tax_type: '',
        tax_rate: 0,
      },
      classificationCodes: [],
      taxTypes: [],
    };
  },
  created() {
    if (this.item) {
      this.formItem = { ...this.item };
    }
    this.fetchClassificationCodes();
    this.fetchTaxTypes();
  },
  methods: {
    async fetchClassificationCodes() {
      try {
        const response = await axios.get('/api/v1/invoiceportal/classification-codes/');
        this.classificationCodes = JSON.parse(response.data);
      } catch (error) {
        console.error('Error fetching classification codes:', error);
      }
    },
    async fetchTaxTypes() {
      try {
        const response = await axios.get('/api/v1/invoiceportal/tax-types/');
        this.taxTypes = JSON.parse(response.data);
      } catch (error) {
        console.error('Error fetching tax types:', error);
      }
    },
    async saveItem() {
      try {
        let response;
        if (this.item) {
          response = await axios.put(`/api/v1/portal-items/${this.item.id}/`, this.formItem);
        } else {
          response = await axios.post('/api/v1/portal-items/', this.formItem);
        }
        this.$emit('itemSaved', response.data);
        this.closeModal();
      } catch (error) {
        console.error('Error saving item:', error);
      }
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-card {
  max-width: 600px;
  max-height: 80vh;
}

.select.is-fullwidth select {
  width: 100%;
}

.select.is-fullwidth {
  width: 100%;
}

.field {
  width: 100%;
}

.control {
  width: 100%;
}

.modal-card-body {
  padding: 1.5rem;
}

.input,
.select select {
  width: 100%;  /* Ensure that input and select fields are 100% of the modal width */
}
</style>

