<template>
  <div id="quickviewDefault" class="quickview" ref="quickview">
    <header class="quickview-header">
      <p class="title">Invoice Details</p>
      <span class="delete" @click="closeQuickview"></span>
    </header>
    <div class="quickview-body">
      <div class="quickview-block is-size-7">
        <p class="pl-4"><strong>Invoice Number:</strong> {{ invoice.invoice_number }}</p>
        <p class="pl-4"><strong>Internal UUID:</strong> {{ invoice.uuid }}</p>
        <p class="pl-4"><strong>Document Type:</strong> {{ invoice.invoice_type }}</p>
        <p class="pl-4"><strong>LHDN Response:</strong></p>
        <div class="card mt-5">
          <div class="card-content">
            <p>{{ invoice.status_message }}</p>
          </div>
        </div>
        <div class="buttons mt-3">
          <button class="button is-link ml-4 is-small" @click="fetchLatestLHDNResponse" :disabled="!canFetchLatestResponse || loading">
            <span v-if="loading" class="spinner"></span> Fetch Updates
          </button>
          <button v-if="canEditInvoice" class="button is-warning is-small" @click="editInvoice" :disabled="!canEdit || loading">Edit</button>
          <button v-if="isSuperAdmin" class="button is-danger is-small" @click="cancelInvoice" :disabled="!canCancel || loading">
            <span v-if="loading" class="spinner"></span> Cancel
          </button>
        </div>
        <div class="columns">
          <div class="column is-5 text-centered">
            <p class="pl-3 mt-3" v-if="invoice.status === 'Validated' && qrCodeUrl"><strong>LHDN QR Code:</strong></p>
            <img class="image is-128x128" v-if="invoice.status === 'Validated' && qrCodeUrl" :src="qrCodeUrl" alt="QR Code">
          </div>
          <div class="column is-7">
            <p class="mt-3 pl-5" v-if="hasFiles"><strong>Archives:</strong></p>
            <a v-if="invoice.file_archive && invoice.file_archive.inputfile_path" class="pl-5" :href="invoice.file_archive.inputfile_path" target="_blank">Original Data</a>
            <br v-if="invoice.file_archive && invoice.file_archive.inputfile_path">
            <a v-if="invoice.file_archive && invoice.file_archive.xml_path" class="pl-5" :href="invoice.file_archive.xml_path" target="_blank">LHDN UBL 2.1 XML</a>
            <br v-if="invoice.file_archive && invoice.file_archive.xml_path">
            <a v-if="invoice.file_archive && invoice.file_archive.pdf_path" class="pl-5" :href="invoice.file_archive.pdf_path" target="_blank">PDF Presentation</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';

export default {
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lhdnResponse: '',
      canEdit: false,
      canCancel: false,
      canFetchLatestResponse: false,
      loading: false,
    };
  },
  computed: {
    qrCodeUrl() {
      return this.invoice.status === 'Validated' ? `${process.env.VUE_APP_QR_CODE_URL}/qr_invoice_lhdn_${this.invoice.uuid}.png` : '';
    },
    canEditInvoice() {
      return this.$store.getters.canEditInvoice;
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
    hasFiles() {
      return this.invoice.file_archive && (this.invoice.file_archive.inputfile_path || this.invoice.file_archive.xml_path || this.invoice.file_archive.pdf_path);
    }
  },
  methods: {
    closeQuickview() {
      this.$refs.quickview.classList.remove('is-active');
      this.$emit('close');
    },
    fetchLatestLHDNResponse() {
      this.loading = true;
      axios.get(`/api/v1/lhdnvalidation/check_submission/${this.invoice.uuid}/`)
        .then(response => {
          this.loading = false;
          this.lhdnResponse = response.data;
          toast({
            message: 'Fetched latest LHDN response successfully!',
            type: 'is-success',
            duration: 5000,
            position: 'top-right',
            dismissible: true
          });
          this.$emit('reload-invoice'); // Reload invoice details to reflect the updated status
        })
        .catch(error => {
          this.loading = false;
          toast({
            message: `Error fetching LHDN response: ${error.message}`,
            type: 'is-danger',
            duration: 5000,
            position: 'top-right',
            dismissible: true
          });
          console.error('Error fetching LHDN response:', error);
        });
    },
    editInvoice() {
      if (this.canEdit) {
        this.$emit('edit', this.invoice);
      }
    },
    cancelInvoice() {
      if (this.canCancel) {
        const reason = prompt('Please enter the reason for cancelling the document:');
        if (reason) {
          if (confirm('Are you sure you want to cancel? This operation cannot be reversed!')) {
            this.loading = true;
            axios.put(`/api/v1/lhdnvalidation/cancel/${this.invoice.uuid}/`, { reason })
              .then(() => {
                this.loading = false;
                toast({
                  message: 'Invoice cancelled successfully!',
                  type: 'is-success',
                  duration: 5000,
                  position: 'top-right',
                  dismissible: true
                });
                this.$emit('reload-invoice'); // Reload invoice details to reflect the updated status
              })
              .catch(error => {
                this.loading = false;
                toast({
                  message: `Error cancelling invoice: ${error.message}`,
                  type: 'is-danger',
                  duration: 5000,
                  position: 'top-right',
                  dismissible: true
                });
                console.error('Error cancelling invoice:', error);
              });
          }
        }
      }
    },
    handleClickOutside(event) {
      if (this.$refs.quickview && !this.$refs.quickview.contains(event.target)) {
        this.closeQuickview();
      }
    },
  },
  mounted() {
    this.canEdit = this.invoice.source === 'UI' && this.invoice.status === 'Submission Failed' || this.invoice.status === 'Invalid' || this.invoice.status === 'Approval Rejected';
    this.canFetchLatestResponse = this.invoice.status === 'Validated' || this.invoice.status === 'Submission Failed' || this.invoice.status === 'Invalid';
    const submissionDate = new Date(this.invoice.modified_at);
    const currentDate = new Date();
    const daysDifference = Math.ceil((currentDate - submissionDate) / (1000 * 60 * 60 * 24));
    this.canCancel = daysDifference <= 3 && this.invoice.status === 'Validated';

    setTimeout(() => {
      document.addEventListener('click', this.handleClickOutside);
    }, 100);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  watch: {
    invoice: {
      handler(newInvoice) {
        this.canEdit = newInvoice.status === 'Submission Failed' || newInvoice.status === 'Invalid';
        this.canFetchLatestResponse = newInvoice.status === 'Validated' || newInvoice.status === 'Invalid';
        const submissionDate = new Date(newInvoice.submission_date);
        const currentDate = new Date();
        const daysDifference = Math.ceil((currentDate - submissionDate) / (1000 * 60 * 60 * 24));
        this.canCancel = daysDifference <= 3 && newInvoice.status === 'Validated';
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
.quickview {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0,0,0,0.1);
  transition: right 0.3s ease;
  z-index: 1000;
}
.quickview.is-active {
  right: 0;
}
.quickview-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f5f5f5;
}
.quickview-body {
  padding: 1rem;
  overflow-y: auto;
}
.card {
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 2px;
}
.spinner {
  margin-right: 5px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 14px;
  height: 14px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
