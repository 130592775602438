<template>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ isEdit ? 'Edit Enrichment' : 'Create Enrichment' }}</p>
          <button class="delete" aria-label="close" @click="$emit('close')"></button>
        </header>
        <section class="modal-card-body">
          <form @submit.prevent="submitForm">
            <div class="field is-small">
              <label class="label is-size-7">Field Name*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEnrichment.field_name" required>
              </div>
            </div>
            <div class="field is-small">
              <label class="label is-size-7">Default Value*</label>
              <div class="control">
                <input class="input is-size-7" type="text" v-model="localEnrichment.default_value" required>
              </div>
            </div>
          </form>
        </section>
        <footer class="buttons modal-card-foot">
          <button class="button is-success is-small" @click="submitForm">{{ isEdit ? 'Update' : 'Create' }}</button>
          <button class="button is-small" @click="$emit('close')">Cancel</button>
          <button class="button is-danger is-small ml-2" v-if="isEdit" @click="confirmDelete">Delete</button>
        </footer>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isEdit: Boolean,
      enrichment: Object,
    },
    data() {
      return {
        localEnrichment: this.enrichment ? { ...this.enrichment } : {
          field_name: '',
          default_value: '',
        },
      };
    },
    methods: {
      submitForm() {
        this.$emit('submit', this.localEnrichment);
      },
      confirmDelete() {
        if (confirm('Are you sure you want to delete this enrichment item?')) {
          this.$emit('delete', this.localEnrichment.id);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .ml-2 {
    margin-left: 0.5rem;
  }
  </style>
  