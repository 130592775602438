<template>
  <div class="section">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/admin">Admin Settings</router-link></li>
        <li class="is-active"><router-link to="/dashboard/user-management" aria-current="true">user-management</router-link></li>
      </ul>
    </nav>
    <div class="container">
      <h1 class="title">User Management</h1>
      <div class="field has-addons">
        <div class="control">
          <input v-model="searchQuery" class="input is-small" type="text" placeholder="Search by username or email">
        </div>
        <div class="control">
          <button class="button is-primary is-small" @click="searchUsers">Search</button>
        </div>
      </div >
      <button class="button is-primary is-small mt-5" @click="openCreateModal">Create User</button>
      <table class="table is-fullwidth is-small mt-5">
        <thead>
          <tr>
            <th class="is-size-7">Username</th>
            <th class="is-size-7">Email</th>
            <th class="is-size-7">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td class="is-size-7">{{ user.username }}</td>
            <td class="is-size-7">{{ user.email }}</td>
            <td class="is-size-7">
              <button class="button is-info is-small" @click="openEditModal(user.id)">Edit</button>
            </td>
          </tr>
        </tbody>
      </table>
      <button v-if="nextPage" class="button is-primary is-small" @click="fetchUsers(nextPage)">Load More</button>
    </div>

    <!-- User Modal -->
    <UserModal 
      :isActive="isModalActive" 
      :isEdit="isEditMode" 
      :user="editUser" 
      :allGroups="allGroups"
      :allSenderEntities="allSenderEntities" 
      :allStoreCodes="allStoreCodes"
      @close="closeModal"
      @save="handleSave"
      @delete="confirmDelete"
      @reset-password="resetPassword"
    />
  </div>
</template>



<script>
import axios from 'axios';
import UserModal from './UserModal.vue'; // Adjust the path as necessary
import { toast } from "bulma-toast"; // Import bulma-toast

export default {
  components: {
    UserModal,
  },
  data() {
    return {
      users: [],
      nextPage: null,
      searchQuery: '',
      isModalActive: false,
      isEditMode: false,
      allGroups: [],
      allSenderEntities: [],
      allStoreCodes: [],
      editUser: this.getEmptyUser(),
    };
  },
  methods: {
    getEmptyUser() {
      return {
        id: '',
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        access_type: 'user',
        sender_entities: [],
        store_code: [],
        groups: [], // Add groups to the empty user object
        password: '', // Add password for new user
      };
    },
    async createUser(user) {
      try {
        const response = await axios.put('/api/v1/user-profile/update/', user, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          }
        });
        this.users.push(response.data);
        this.closeModal();
        toast({
          message: "User created successfully",
          type: "is-success",
          dismissible: true,
          duration: 3000,
          position: "top-right"
        });
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        this.showErrorToast(error);
      }
    },
    async updateUser(user) {
      try {
        const response = await axios.put(`/api/v1/user-profile/update/${user.id}/`, user, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          }
        });
        const index = this.users.findIndex(u => u.id === user.id);
        if (index !== -1) {
          this.users.splice(index, 1, response.data); // Use splice to update the user in the array
        }
        this.closeModal();
        toast({
          message: "User updated successfully",
          type: "is-success",
          dismissible: true,
          duration: 3000,
          position: "top-right"
        });
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        this.showErrorToast(error);
      }
    },
    openCreateModal() {
      this.isEditMode = false;
      this.editUser = this.getEmptyUser();
      this.isModalActive = true;
    },
    async openEditModal(userId) {
      try {
        const response = await axios.get(`/api/v1/user-profile/${userId}/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          }
        });
        // Directly assign sender_entities and store_code
        this.editUser = {
          ...response.data,
          sender_entities: response.data.sender_entities.map(e => e.id),
          store_code: response.data.store_code.map(s => s.id),
          groups: response.data.groups.map(g => g.id),
          id: userId,
        };

        this.isEditMode = true;
        this.isModalActive = true;
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        this.showErrorToast(error);
      }
    },
    closeModal() {
      this.isModalActive = false;
    },
    async handleSave(user) {
      console.log('User to save:', user);

      // Validation logic
      if (!user.username || !user.email || !user.first_name || !user.last_name) {
        toast({
          message: "Please fill in all required fields.",
          type: "is-danger",
          dismissible: true,
          duration: 5000,
          position: "top-right"
        });
        return;
      }

      if (user.sender_entities.length === 0 && user.store_code.length === 0 && user.groups.length === 0) {
        toast({
          message: "Please select at least one sender entity, store code, or role.",
          type: "is-danger",
          dismissible: true,
          duration: 5000,
          position: "top-right"
        });
        return;
      }

      if (this.isEditMode) {
        await this.updateUser(user);
      } else {
        await this.createUser(user);
      }
    },
    async confirmDelete(userId) {
      if (confirm('Are you sure you want to delete this user?')) {
        this.deleteUser(userId);
      }
    },
    async deleteUser(userId) {
      try {
        await axios.delete(`/api/v1/users/${userId}/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          }
        });
        this.users = this.users.filter(user => user.id !== userId);
        toast({
          message: "User deleted successfully",
          type: "is-success",
          dismissible: true,
          duration: 3000,
          position: "top-right"
        });
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        this.showErrorToast(error);
      }
    },
    async resetPassword(userId) {
      const newPassword = prompt('Enter new password:');
      if (newPassword) {
        try {
          await axios.post('/api/v1/users/set_password/', {
            id: userId,
            new_password: newPassword
          }, {
            headers: {
              'Authorization': `Token ${localStorage.getItem('token')}`,
            }
          });
          alert('Password reset successfully');
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          this.showErrorToast(error);
        }
      }
    },
    async fetchUsers(url = null, append = true) {
      const apiUrl = url || '/api/v1/users-list/';
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          },
        });
        if (append) {
          this.users = this.users.concat(response.data.results);
        } else {
          this.users = response.data.results;
        }
        this.nextPage = response.data.next;
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        this.showErrorToast(error);
      }
      console.log('Next Page URL:', this.nextPage);

    },
    async fetchAdditionalData() {
      try {
        const senderEntitiesResponse = await axios.get('/api/v1/all-senders', {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          },
        });
        this.allSenderEntities = senderEntitiesResponse.data;

        const storeCodesResponse = await axios.get('/api/v1/all-store-codes/', {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          },
        });
        this.allStoreCodes = storeCodesResponse.data;

        const groupsResponse = await axios.get('/api/v1/groups/', {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          },
        });
        this.allGroups = groupsResponse.data;
      } catch (error) {
        console.error('There was a problem with fetching additional data:', error);
        this.showErrorToast(error);
      }
    },
    async searchUsers() {
      await this.fetchUsers(`/api/v1/users-list/?search=${this.searchQuery}`, false);
    },
    showErrorToast(error) {
      const errorMessage = error.response?.data?.detail || 'An error occurred. Please try again.';
      toast({
        message: errorMessage,
        type: "is-danger",
        dismissible: true,
        duration: 5000,
        position: "top-right"
      });
    }
  },
  async created() {
    await this.fetchUsers();
    await this.fetchAdditionalData();
  },
};
</script>

<style scoped>
/* Add any styles you need for your component here */
</style>
