<template>
  <nav class="breadcrumb mt-5" aria-label="breadcrumbs">
      <ul>
          <li><router-link to="/dashboard">Dashboard</router-link></li>
          <li class="is-active"><router-link to="/dashboard/Items" aria-current="true">Items</router-link></li>
      </ul>
  </nav>
  <div class="container">
      <h1 class="title">Items</h1>
      <button class="button is-primary" @click="showModal = true">Add New Item</button>

      <AddItem v-if="showModal" @close="showModal = false" @itemSaved="addItem"></AddItem>
      <AddItem v-if="showEditModal" :item="selectedItem" @close="showEditModal = false" @itemSaved="updateItem"></AddItem>

      <div class="box mt-5">
          <div class="field is-6">
              <label class="label">Search</label>
              <div class="control">
                  <input v-model="searchQuery" class="input" type="text" placeholder="Search by Classification Code or Description">
              </div>
          </div>
          <table class="table is-fullwidth">
              <thead>
                  <tr class="is-size-7">
                      <th>Classification</th>
                      <th>Description</th>
                      <th>Tax Type</th>
                      <th>Tax Rate</th>
                      <th>Actions</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="item in filteredItems" :key="item.id" class="is-size-7">
                      <td>{{ item.classification }}</td>
                      <td>{{ item.item_description }}</td>
                      <td>{{ item.tax_type }}</td>
                      <td>{{ item.tax_rate }}</td>
                      <td>
                          <button class="button is-small is-info" @click="editItem(item)">Edit</button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import AddItem from '@/components/AddItem.vue';

export default {
    name: 'ItemTables',
    components: {
        AddItem,
    },
    data() {
        return {
            items: [],
            searchQuery: '',
            showModal: false,
            showEditModal: false,
            selectedItem: null,
        };
    },
    computed: {
        filteredItems() {
            return this.items.filter(item =>
                item.classification.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                item.item_description.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
    },
    methods: {
        async fetchItems() {
            try {
                const response = await axios.get('/api/v1/portal-items');
                this.items = response.data;
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        },
        addItem(newItem) {
            this.items.push(newItem);
            this.showModal = false;
        },
        editItem(item) {
            this.selectedItem = item;
            this.showEditModal = true;
        },
        updateItem(updatedItem) {
            const index = this.items.findIndex(item => item.id === updatedItem.id);
            if (index !== -1) {
                // Directly update the reactive array
                this.items[index] = updatedItem;
            }
            this.showEditModal = false;
        },
    },
    created() {
        this.fetchItems();
    },
};
</script>


<style scoped>
.modal-card {
  max-width: 800px;
  max-height: 60vh;
}
</style>
