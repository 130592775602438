<template>
  <div class="user-dashboard">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
      </ul>
    </nav>
    <h1 class="title">Dashboard</h1>

    <!-- Tab Navigation -->
    <div class="tabs is-boxed">
      <ul>
        <li :class="{ 'is-active': activeTab === 'overview' }" @click="activeTab = 'overview'">
          <a>Overview</a>
        </li>
        <li :class="{ 'is-active': activeTab === 'graphs' }" @click="activeTab = 'graphs'">
          <a>Graphs</a>
        </li>
      </ul>
    </div>

    <!-- Tab Content -->
    <div v-if="activeTab === 'overview'" class="container">
      <!-- First Row: YTD Counts -->
      <div class="columns is-multiline is-flex is-align-items-stretch">
        <div class="column is-one-quarter">
          <router-link to="/dashboard/invoices">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-5 has-text-white">LHDN Submission (YTD)</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ formatNumber(thisYearValidatedCount) }}</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column is-one-quarter">
          <router-link :to="{ path: '/dashboard/invoices', query: { tab: 'Validated' } }">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-5 has-text-white pb-4">LHDN Validated (YTD)</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ formatNumber(thisYearValidatedOnlyCount) }}</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column is-one-quarter">
          <router-link :to="{ path: '/dashboard/invoices', query: { tab: 'Submission Failed' } }">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-5 has-text-white">Submission Failed (YTD)</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ formatNumber(thisYearLhdnNotSuccessfulCount) }}</p>
              </div>
            </div>
          </router-link>
        </div>
        <div v-if="canApprove" class="column is-one-quarter">
          <router-link to="/dashboard/invoices/approval">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-5 has-text-white">Pending Approval (YTD)</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ formatNumber(thisYearPendingApprovalCount) }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <!-- Second Row: Monthly Counts -->
      <div class="columns is-multiline is-flex is-align-items-stretch">
        <div class="column is-one-quarter">
          <router-link to="/dashboard/invoices">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-5 has-text-white">LHDN Submission (Monthly)</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ formatNumber(thisMonthValidatedCount) }}</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column is-one-quarter">
          <router-link :to="{ path: '/dashboard/invoices', query: { tab: 'Validated' } }">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-5 has-text-white">LHDN Validated (Monthly)</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ formatNumber(thisMonthValidatedOnlyCount) }}</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column is-one-quarter">
          <router-link :to="{ path: '/dashboard/invoices', query: { tab: 'Submission Failed' } }">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-5 has-text-white">Submission Failed (Monthly)</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ formatNumber(thisMonthLhdnNotSuccessfulCount) }}</p>
              </div>
            </div>
          </router-link>
        </div>
        <div v-if="canApprove" class="column is-one-quarter">
          <router-link to="/dashboard/invoices/approval">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-5 has-text-white">Pending Approval (Monthly)</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ formatNumber(thisMonthPendingApprovalCount) }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- Graphs Tab Content -->
    <div v-if="activeTab === 'graphs'" class="container">
      <div class="columns">
        <div class="column is-8">
          <line-chart-component :data="invoicesOverMonths"></line-chart-component>
        </div>
        <div class="column is-4">
          <pie-chart-component :data="topBuyers"></pie-chart-component>
        </div>
      </div>
      <div class="column">
        <bar-chart-component :data="invoicesBySender"></bar-chart-component>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LineChartComponent from '@/components/LineChart.vue';
import BarChartComponent from '@/components/BarChart.vue';
import PieChartComponent from '@/components/PieChart.vue';

export default {
  name: 'UserDashboard',
  components: {
    LineChartComponent,
    BarChartComponent,
    PieChartComponent,
  },
  data() {
    return {
      activeTab: 'overview', // Default tab is 'overview'
      // YTD Counts
      thisYearValidatedCount: 0,
      thisYearValidatedOnlyCount: 0,
      thisYearLhdnNotSuccessfulCount: 0,
      thisYearPendingApprovalCount: 0,
      // Monthly Counts
      thisMonthValidatedCount: 0,
      thisMonthValidatedOnlyCount: 0,
      thisMonthLhdnNotSuccessfulCount: 0,
      thisMonthPendingApprovalCount: 0,
      // Graph Data
      invoicesOverMonths: [],
      topBuyers: [],
      invoicesBySender: [],
    };
  },
  computed: {
    canApprove() {
      return this.$store.getters.canApproveInvoice;
    },
    canCreateInvoice() {
      return this.$store.getters.canCreateInvoice;
    }
  },
  methods: {
    formatNumber(value) {
      return value.toLocaleString();
    },
    fetchData() {
      axios.get('/api/v1/invoice-counts/').then(response => {
        // YTD Counts
        this.thisYearValidatedCount = response.data.this_year_validated_count;
        this.thisYearValidatedOnlyCount = response.data.this_year_validated_only_count;
        this.thisYearLhdnNotSuccessfulCount = response.data.this_year_lhdn_not_successful_count;
        this.thisYearPendingApprovalCount = response.data.this_year_pending_approval_count;
        // Monthly Counts
        this.thisMonthValidatedCount = response.data.this_month_validated_count;
        this.thisMonthValidatedOnlyCount = response.data.this_month_validated_only_count;
        this.thisMonthLhdnNotSuccessfulCount = response.data.this_month_lhdn_not_successful_count;
        this.thisMonthPendingApprovalCount = response.data.this_month_pending_approval_count;
      });
      axios.get('/api/v1/invoices-over-months/').then(response => {
        this.invoicesOverMonths = response.data;
      });
      axios.get('/api/v1/top-buyers/').then(response => {
        this.topBuyers = response.data;
      });
      axios.get('/api/v1/invoices-by-sender/').then(response => {
        this.invoicesBySender = response.data;
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.user-dashboard {
  padding: 20px;
}

.card-content {
  cursor: pointer;
}

.equal-height {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.has-background-grey-darker {
  background-color: #2e2e2e;
}

.has-text-white {
  color: #ffffff;
}

.tabs ul li.is-active a {
  color: #3273dc;
  border-bottom-color: #3273dc;
}
</style>
